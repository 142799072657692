<!-------------------------------------------------
description: 告警列表 规则配置
/**
 *@author xiaosanye 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <ykc-operation-dialog
    title="短信通知规则"
    top="30vh"
    :before-close="onCLose"
    :show.sync="showDialog"
    :before-confirm="submitForm"
    :onClose="onCLose"
    :before-cancel="beforeCancel1">
    <div slot="content">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleFormDR">
        <ykc-form-item
          :label="item.label"
          v-for="item in ruleConfigs"
          :prop="item.key"
          :key="item.key">
          <ykc-checkbox
            :data="checkBoxData"
            :widthArr="[5, 5]"
            v-model="ruleForm[item.key]"
            :configSet="checkBoxConfig"></ykc-checkbox>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-operation-dialog>
</template>

<script>
  import { customerAccount, omManagementApi } from '@/service/apis';
  import YkcCheckbox from '@/components/ykc-ui/checkbox/src/main.vue';
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog/src/main.vue';

  export default {
    name: 'SMSNotificationRules',
    components: { YkcCheckbox, YkcOperationDialog },
    props: {
      show2: {
        type: Boolean,
        default: false,
      },
      row: {
        type: Object,
        default: () => ({}),
      },
      configs: {
        type: Array,
        default: () => [],
      },
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        // 类型 1=故障 2=温度超高 3=SOC异常 4=电压电流异常 5=实时数据异常 6=离线
        ruleConfigs: [
          { key: 'thePileFaultStopsCharging', label: '电桩故障停充', id: 1 },
          { key: 'ultraHighTemperature', label: '温度超高', id: 2 },
          { key: 'socException', label: 'SOC异常', id: 3 },
          { key: 'voltageAndCurrentAbnormalities', label: '电压电流异常', id: 4 },
          { key: 'realTimeDataAnomalies', label: '实时数据异常', id: 5 },
          { key: 'theElectricPileIsOffline', label: '电桩离线', id: 6 },
        ],
        checkBoxConfig: {
          label: 'label',
          value: 'value',
        },
        checkBoxData: [
          {
            value: '1',
            label: '商户管理员',
          },
          {
            value: '2',
            label: '电站管理员',
          },
        ],
        treeData: [
          {
            id: 1,
            name: '工作台',
            parentId: 0,
            sort: 0,
            subNodes: [],
          },
          {
            id: 2,
            name: '财务管理',
            parentId: 0,
            sort: 0,
            subNodes: [
              {
                id: 3,
                name: '账户管理',
                parentId: 2,
                sort: 0,
                subNodes: [
                  {
                    id: 4,
                    name: '公司充电账户',
                    parentId: 3,
                    sort: 0,
                    subNodes: [],
                  },
                ],
              },
              {
                id: 9,
                name: '发票管理',
                parentId: 2,
                sort: 0,
                subNodes: [
                  {
                    id: 10,
                    name: '发票列表',
                    parentId: 9,
                    sort: 0,
                    subNodes: [],
                  },
                ],
              },
            ],
          },
        ],
        treeDataOrg: [],
        treeDataUser: [],
        operatorProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        ruleForm: {
          // 电桩故障停充
          thePileFaultStopsCharging: [],
          // 温度超高
          ultraHighTemperature: [],
          /** SOC异常
           * socException
           * */
          socException: [],
          // 电桩离线
          theElectricPileIsOffline: [],
          //   电压电流异常
          voltageAndCurrentAbnormalities: [],
          //   实时数据异常
          realTimeDataAnomalies: [],
        },
        showDialog: false,
        userAndOrgInfo: {}, // 用户和机构链接
      };
    },
    computed: {
      linkText() {
        // linkText: '分配扣回导入模板.xlsx', // 模板文本
        return this.ruleForm.operateObject === '1'
          ? '客户批量分配扣回.xlsx'
          : '用户批量分配扣回.xlsx';
      },
      modelUrl() {
        return this.ruleForm.operateObject === '1'
          ? `${process.env.VUE_APP_OSS}/finance/upload/%E5%AE%A2%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`
          : `${process.env.VUE_APP_OSS}/finance/upload/%E7%94%A8%E6%88%B7%E6%89%B9%E9%87%8F%E5%88%86%E9%85%8D%E6%89%A3%E5%9B%9E.xlsx`;
      },
      rules() {
        const obj = Object.create(null);
        this.ruleConfigs.forEach(item => {
          obj[item.key] = [
            { type: 'array', required: true, message: `请选择${item.label}`, trigger: 'change' },
          ];
        });
        return obj;
      },
    },
    watch: {
      show2(newValue, oldValue) {
        console.log('oldValue:', oldValue);
        this.showDialog = newValue;
      },
      configs: {
        immediate: false,
        deep: true,
        handler(newConfigs) {
          if (Array.isArray(newConfigs)) {
            newConfigs.forEach(conf => {
              this.setConfig(conf);
            });
          }
        },
      },
    },
    methods: {
      setConfig(conf) {
        // operateFlag 0=否 1=是  是否商户管理员
        // stationFlag 0=否 1=是 是否站管理员
        const { operateFlag, stationFlag, type } = conf;
        const propertyName = this.ruleConfigs.find(item => item.id === type)?.key;
        // console.log('-> propertyName', propertyName);
        if (operateFlag === 0 && stationFlag === 0) {
          this.ruleForm[propertyName] = [];
        }
        if (operateFlag === 1 && stationFlag === 0) {
          this.ruleForm[propertyName] = ['1'];
        }
        if (operateFlag === 0 && stationFlag === 1) {
          this.ruleForm[propertyName] = ['2'];
        }
        if (operateFlag === 1 && stationFlag === 1) {
          this.ruleForm[propertyName] = ['1', '2'];
        }
      },
      askClick(tips) {
        console.log('show help');
        this.$message.warning(tips);
      },
      onOpObjChange(eventTarget) {
        console.log('evt:', eventTarget);
        this.$nextTick(() => {
          this.$refs.ruleFormDR.clearValidate();
        });
      },
      doResetSomeField() {
        this.ruleForm = {
          ...this.ruleForm,
          /**
           * 操作金额，操作模式为1，2时必填
           */
          operateAmount: '',
          /**
           * 选择对象id列表，操作模式为1，2时必填
           */
          operateIdList: '',
          /**
           * 操作对象列表，操作模式为3时必填
           */
          operateList: '',
          /**
           * 操作模式：1等额 2 补齐 3自定义
           */
          operateMode: '1',
          /**
           * 操作对象：1客户 2用户
           */
          operateObject: '1',
        };
        this.$nextTick(() => {
          this.$refs?.ruleFormDR?.clearValidate();
        });
      },
      // 监听操作模式改变 1 2 3
      onOperateModeChange(val) {
        console.log('onOperateModeChange val:', val);
        if (['1', '2'].includes(val)) {
          this.ruleForm.operateIdList = [];
        }
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('distribution-close', false);
        done();
      },
      onCLose() {
        // this.showDialog = false;
        this.$emit('distribution-close', false);
      },
      onConfirm(done) {
        console.log('done:', done);
      },
      handleTreeDataOrg() {
        this.ruleForm.operateIdList = this.$refs.objectTreeOrg.getCheckedKeys(false) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleTreeDataUser() {
        this.ruleForm.operateIdList = this.$refs.objectTreeUser.getCheckedKeys(false) || [];
        // console.log('this.ruleForm.stationList', checkedKeys, this.ruleForm.stationList);
      },
      handleRemove() {
        this.ruleForm.file = undefined;
      },
      // 查询用户和组织信息  财务
      queryUserAndOrgInfo4Finance() {
        customerAccount.queryUserAndOrgInfo4Finance({ id: this.row.orgId }).then(response => {
          this.userAndOrgInfo = response;
          this.treeDataUser = this.userAndOrgInfo.users.map(item => ({
            id: item.id,
            name: item.userAccount,
          }));
          this.treeDataOrg = this.userAndOrgInfo.orgs.map(item => ({
            id: item.id,
            name: item.orgName,
          }));
        });
      },
      fileUpload(file, fileList, isCheck) {
        // check the value of isCheck
        if (isCheck) {
          // assign the raw value of file to the file object on ruleForm
          this.ruleForm.file = file.raw;
          // make a request to the importDistributionDeduction api using the raw value of file as a parameter
          // importMsg {"orgId":"1","operateObject","1","operateMode":"3"}
          const formData = new FormData();
          // Create a variable to hold our JSON string
          const msg = JSON.stringify({
            orgId: this.ruleForm.orgId,
            operateObject: this.ruleForm.operateObject,
            operateMode: this.ruleForm.operateMode,
          });
          // Log the value of msg to the console
          console.log('upload msg:', msg);
          // Create an object to contain all the values we wish to include.
          const param = {
            file: this.ruleForm.file,
            paramJson: msg,
          };
          // Loop through each item in the param object, and append it to the FormData object.
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });

          customerAccount
            .importDistributionDeduction(formData)
            .then(res => {
              console.log('res---导入分配扣回信息:\n', res);
              this.$message({
                message: '导入文件成功',
                type: 'success',
              });
              this.ruleForm.operateList = res || [];
            })
            .catch(() => {
              this.$refs.file.fileList = [];
            });
        }

        this.$refs?.ruleFormDR?.validateField('file');
      },
      //  转换配置
      parseConfig(item, arr) {
        const { id, key } = item;
        const val = this.ruleForm[key].slice();
        console.log('-> val', val);
        if (val === []) {
          arr.push({
            id,
            operateFlag: 0,
            stationFlag: 0,
            type: item.id,
          });
        }
        if (JSON.stringify(val) === JSON.stringify(['1'])) {
          arr.push({
            id,
            operateFlag: 1,
            stationFlag: 0,
            type: item.id,
          });
        }
        if (JSON.stringify(val) === JSON.stringify(['2'])) {
          arr.push({
            id,
            operateFlag: 0,
            stationFlag: 1,
            type: item.id,
          });
        }
        if (
          JSON.stringify(val) === JSON.stringify(['1', '2']) ||
          JSON.stringify(val) === JSON.stringify(['2', '1'])
        ) {
          arr.push({
            id,
            operateFlag: 1,
            stationFlag: 1,
            type: item.id,
          });
        }
      },
      submitForm(done) {
        this.$refs?.ruleFormDR?.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              const arr = [];
              // operateFlag 0=否 1=是  是否商户管理员
              // stationFlag 0=否 1=是 是否站管理员
              this.ruleConfigs.forEach(item => {
                this.parseConfig(item, arr);
              });

              omManagementApi
                .saveAlarmSmsRule({
                  list: arr,
                })
                .then(res => {
                  console.log(res);
                  done();
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  this.$emit('distribution-close', false);
                  this.$emit('refresh-list', true);
                })
                .catch(err => {
                  console.error(err);
                  if (err) {
                    this.$message.warning(err?.resultDesc);
                  }
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
    },
  };
</script>

<style scoped></style>
