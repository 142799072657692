<!-------------------------------------------------
description:  运维中心 告警信息 告警列表
/**
 *@author xiaosanbi 
 *@date 2023/2/27
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      table-title="告警列表"
      :show-header="true"
      :search-data="searchData"
      :search-params="searchParams"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons"
      :header-buttons="headerButtons.filter(item => item.enabled())"
      :request-method="omManagementApi.omAlarmInfoList"
      @query-form="queryForm"
      @clear-form="clearForm" />
    <!-------------------------------------------------
    description:规则配置
    --------------------------------------------------->
    <rule-config :show2="showRuleConfigDialog" @distribution-close="onDistributionClose" />
    <!-------------------------------------------------
    description:短信通知规则
    --------------------------------------------------->
    <s-m-s-notification-rules
      :show2="showSMSNotificationRuleDialog"
      :configs="smsInfoRulesConfigs"
      @refresh-list="querySMSInfoRule"
      @distribution-close="onDistributionClose" />
  </div>
</template>

<script>
  import { omManagementApi } from '@/service/apis';
  import { code, getLastTime, offlineExport } from '@/utils';

  import RuleConfig from '../components/RuleConfig.vue';
  import CustomList from '@/components/biz/CustomList.vue';
  import SMSNotificationRules from '../components/SMSNotificationRules.vue';

  export default {
    name: 'OMAlarmsInfoList',
    components: { SMSNotificationRules, RuleConfig, CustomList },
    data() {
      return {
        currentRow: {},
        currentRowSMS: {},
        showRuleConfigDialog: false,
        showSMSNotificationRuleDialog: false,
        smsInfoRulesConfigs: [], //  短信通知规则配置
        searchParams: {
          date: [],
        },
        // 告警事项
        //  1=电桩故障 2=温度超高 3=soc异常 4=电桩离线 5=电压电流异常 6=实时数据异常
        dictionaryTypes: [
          { id: '1', name: '电桩故障' },
          { id: '2', name: '温度超高' },
          { id: '3', name: 'soc异常' },
          { id: '4', name: '电桩离线' },
          { id: '5', name: '电压电流异常' },
          { id: '6', name: '实时数据异常' },
        ],
        // 平台响应动作
        // 0=无 1=停止充电 2=关停充电桩
        dictionaryActions: [
          { id: '0', name: '无' },
          { id: '1', name: '停止充电' },
          { id: '2', name: '关停充电桩' },
        ],
        tableColumns: [
          { label: '充电终端', prop: 'terminal', scopedSlots: { defaultTitle: () => '' } },
          { label: '终端编码', prop: 'gunCode', scopedSlots: { defaultTitle: () => '' } },
          { label: '充电站', prop: 'stationName', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '告警事项',
            prop: 'type',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { type } = row;
                const label =
                  this.dictionaryTypes.find(item => Number(item.id) === Number(type))?.name ?? '—';
                return <span>{label}</span>;
              },
            },
          },
          // { label: '告警码', prop: 'code', scopedSlots: { defaultTitle: () => '' } },
          { label: '告警时间', prop: 'alarmTime', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '平台响应动作',
            prop: 'action',
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                const { action } = row;
                const label =
                  this.dictionaryActions.find(item => Number(item.id) === Number(action))?.name ??
                  '—';
                return <span>{label}</span>;
              },
            },
          },
        ],
        tableOperateButtons: [],
      };
    },
    computed: {
      omManagementApi() {
        return omManagementApi;
      },
      headerButtons() {
        return [
          {
            enabled: () => code('maintain:alarm:sms'),
            label: '通知规则',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              this.showSMSNotificationRuleDialog = true;
            },
          },
          {
            enabled: () => code('maintain:alarm:rule'),
            label: '规则配置',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              this.showRuleConfigDialog = true;
            },
          },
          {
            enabled: () => code('maintain:alarm:export'),
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'alarm',
                  jsonNode: {
                    downloadKey: 'alarm',
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            key: 'date',
            label: '告警时间',
            comName: 'YkcDatePicker',
            placeholder: '请选择告警时间',
          },
          {
            comName: 'YkcInput',
            key: 'terminal',
            label: '充电终端',
            placeholder: '请输入充电终端',
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '充电站',
            placeholder: '请输入充电站',
          },
          {
            comName: 'YkcDropdown',
            key: 'type',
            label: '告警事项',
            placeholder: '请选择告警事项',
            data: [...this.dictionaryTypes],
          },
          {
            comName: 'YkcDropdown',
            key: 'action',
            label: '平台响应动作',
            placeholder: '请选择平台响应动作',
            data: [...this.dictionaryActions],
          },
        ];
      },
    },
    created() {
      // 近30天初始化时间
      const startDate = getLastTime('yyyy-MM-dd', 30);
      const endDate = getLastTime('yyyy-MM-dd', 0);

      this.searchParams.date = [startDate, endDate];
      this.searchParams.startTime = startDate;
      this.searchParams.endTime = endDate;

      this.querySMSInfoRule();
    },
    methods: {
      queryForm(searchData) {
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.date;
        }
      },
      clearForm() {
        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.date = [startDate, endDate];
        this.searchParams.startTime = startDate;
        this.searchParams.endTime = endDate;

        this.querySMSInfoRule();
      },
      querySMSInfoRule() {
        this.omManagementApi
          .querySmsRule({})
          .then(response => {
            console.log('-> response', response);
            this.smsInfoRulesConfigs = response;
          })
          .catch(err => {
            console.error(err);
            console.error(`querySmsRule error:${err}`, err);
            this.smsInfoRulesConfigs = [
              {
                id: 1,
                type: 1,
                operateFlag: 0,
                stationFlag: 0,
              },
            ];
          });
      },
      // 分配回扣 关闭 事件
      onDistributionClose(e, val) {
        console.log('e:', e, val);
        this.showRuleConfigDialog = false;
        this.showSMSNotificationRuleDialog = false;
      },
    },
  };
</script>

<style scoped></style>
